import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { catchError, concat, filter, map, mergeMap, of, switchMap } from 'rxjs';
import { CourseActionsPayload, LectureV3, PartialEntity } from '../../models';
import { AnnotationService, CourseService, InstructorService, LoaderService } from '../../services';
import { isDefined } from '../../utils';
import { CourseActions } from '../course';
import { CourseActionsV2 } from './course.actions';
import { selectCourseV2 } from './course.selectors';

@Injectable()
export class CourseDetailsV2Effects {
  constructor(
    private actions$: Actions,
    private coursesService: CourseService,
    private loaderService: LoaderService,
    private instructorService: InstructorService,
    private readonly store: Store,
    private readonly annotationService: AnnotationService
  ) {}

  // Instructor
  loadInstructorCourseDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadCourseDetails),
      switchMap((params) => {
        this.loaderService.loaderAction('loading-instructor-courses-detailsV2', 'loading');
        return this.coursesService.getInstructorCourseDetails(params.courseId).pipe(
          map((data) => {
            this.loaderService.loaderAction('loading-instructor-courses-detailsV2', 'success');
            return CourseActionsV2.loadCourseDetailsSuccess(data);
          }),
          catchError((error) => {
            this.loaderService.loaderAction('loading-instructor-courses-detailsV2', 'failed');
            return of(CourseActionsV2.loadCourseDetailsFail({ error }));
          })
        );
      })
    )
  );

  loadCourseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadCourseDetailsSuccess),
      concatLatestFrom(() => this.store.select(selectCourseV2)),
      mergeMap(([, courseDetail]) => {
        return of(
          CourseActionsV2.loadAnnotationsV2({ projectId: courseDetail.project }),
          CourseActions.loadHistory({ projectId: courseDetail.project })
        );
      })
    )
  );

  upsertCourse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.upsertCourse),
      switchMap(({ courseDetails }) => {
        this.loaderService.loaderAction('course-auto-saving', 'processing');
        return this.coursesService
          .updateCourse(courseDetails.id, {
            ...courseDetails,
            user: courseDetails.user.id,
          })
          .pipe(
            map((courseDetails) => {
              this.loaderService.loaderAction('course-auto-saving', 'completed');
              return CourseActionsV2.upsertCourseSuccess({ courseDetails });
            }),
            catchError((error) => {
              this.loaderService.loaderAction('course-auto-saving', 'completed');
              return of(CourseActionsV2.upsertCourseError({ error }));
            })
          );
      })
    )
  );

  loadInstructorCourseLearner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadCourseLearner),
      switchMap((params) => {
        setTimeout(() => {
          this.loaderService.loaderAction('loading-instructor-courses-learnerV2', 'loading');
        });
        return this.coursesService.getInstructorCourseLearner(params.courseId).pipe(
          map((data) => {
            this.loaderService.loaderAction('loading-instructor-courses-learnerV2', 'success');
            return CourseActionsV2.loadCourseLearnerSuccess(data);
          }),
          catchError((error) => of(CourseActionsV2.loadCourseLearnerFail({ error })))
        );
      })
    )
  );

  loadInstructorCourseCurriculum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadCourseCurriculum),
      switchMap(({ courseId, params }) => {
        setTimeout(() => {
          this.loaderService.loaderAction('loading-instructor-courses-curriculumV2', 'loading');
        });
        return this.coursesService.getInstructorCourseCurriculum(courseId, params).pipe(
          map((data) => {
            this.loaderService.loaderAction('loading-instructor-courses-curriculumV2', 'success');
            return CourseActionsV2.loadCourseCurriculumSuccess(data);
          }),
          catchError((error) => of(CourseActionsV2.loadCourseCurriculumFail({ error })))
        );
      })
    )
  );

  // Section
  createSectionV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.createSectionV2),
      filter(isDefined),
      switchMap(({ section }) => {
        let payload = {
          id: section.id,
          course: section.course,
          title: section.title,
          position: section.position,
        };
        return this.coursesService.upsertSectionV3(payload).pipe(
          map(() => CourseActionsV2.upsertSectionSuccessV2()),
          catchError(() => of(CourseActionsV2.upsertSectionErrorV2()))
        );
      })
    )
  );

  upsertSectionV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.upsertSectionV2),
      filter(isDefined),
      switchMap(({ section }) => {
        let payload = {
          id: section.id,
          title: section.title,
        };
        return this.coursesService.upsertSectionV3(payload).pipe(
          map(() => CourseActionsV2.upsertSectionSuccessV2()),
          catchError(() => of(CourseActionsV2.upsertSectionErrorV2()))
        );
      })
    )
  );

  deleteSectionV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.deleteSectionV2),
      switchMap(({ sectionId, params }) =>
        this.coursesService.deleteSectionV3(sectionId, params).pipe(
          map(() => CourseActionsV2.deleteSectionSuccessV2()),
          catchError(() => of(CourseActionsV2.deleteSectionErrorV2()))
        )
      )
    )
  );

  deleteSubsection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.deleteSubsectionV2),
      switchMap(({ subsectionId }) =>
        this.coursesService.deleteSubsectionV3(subsectionId).pipe(
          map(() => CourseActionsV2.deleteSubsectionSuccessV2()),
          catchError(() => of(CourseActionsV2.deleteSubsectionErrorV2()))
        )
      )
    )
  );

  // Courses lecture
  createLectureV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.createLectureV2),
      switchMap(({ subsection, lecture }) =>
        concat(this.coursesService.upsertLectureV3(lecture!), this.coursesService.upsertSubsectionV3(subsection!)).pipe(
          map(() => CourseActionsV2.createLectureSuccessV2({ subsection, lecture })),
          catchError(() => of(CourseActionsV2.createLectureErrorV2()))
        )
      )
    )
  );

  upsertLectureV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.upsertLectureV2),
      switchMap(({ lecture, lectureResources }) =>
        concat(
          this.coursesService.upsertLectureV3(_.omitBy(lecture!, _.isNil) as PartialEntity<LectureV3>).pipe(
            map((lecture) => {
              return CourseActionsV2.upsertLectureSuccessV2({ lecture });
            }),
            catchError(() => of(CourseActionsV2.upsertLectureErrorV2()))
          ),
          ...lectureResources.map((lectureResource) =>
            this.coursesService.upsertLectureResourceV3(lectureResource).pipe(
              map(() => CourseActionsV2.upsertLectureResourceSuccessV2()),
              catchError(() => of(CourseActionsV2.upsertLectureResourceErrorV2()))
            )
          )
        )
      )
    )
  );

  deleteLectureResourceV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.deleteLectureResourceV2),
      switchMap(({ lectureResourceId }) =>
        this.instructorService.deleteLectureResource(lectureResourceId).pipe(
          map(() => CourseActionsV2.deleteLectureResourceSuccessV2()),
          catchError(() => of(CourseActionsV2.deleteLectureResourceErrorV2()))
        )
      )
    )
  );

  // Quiz
  createQuizV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.createQuizV2),
      switchMap(({ subsection, quiz }) =>
        concat(
          this.coursesService.upsertSubsectionV3(_.omit(subsection!, 'quiz')),
          this.coursesService.upsertQuizV3(quiz!),
          this.coursesService.upsertSubsectionV3({ id: subsection!.id, quiz: quiz!.id })
        ).pipe(
          map(() => CourseActionsV2.createQuizSuccessV2()),
          catchError(() => of(CourseActionsV2.createQuizErrorV2()))
        )
      )
    )
  );

  upsertQuizV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.upsertQuizV2),
      switchMap((quiz) =>
        this.coursesService.upsertQuizV3(quiz.quiz).pipe(
          map(() => CourseActionsV2.upsertQuizSuccessV2()),
          catchError(() => of(CourseActionsV2.upsertQuizErrorV2()))
        )
      )
    )
  );

  createQuizQuestionV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.createQuizQuestionV2),
      switchMap(({ quizQuestion, quizAnswers }) =>
        concat(
          // need to remove null values, i.e. lecture since it's not required
          this.coursesService.upsertQuizQuestionV3(_.omitBy(quizQuestion!, _.isNil) as any),
          ...quizAnswers?.map((quizAnswer) => this.coursesService.upsertQuizAnswerV3(quizAnswer!))
        ).pipe(
          map(() => CourseActionsV2.upsertQuizQuestionSuccessV2()),
          catchError(() => of(CourseActionsV2.upsertQuizQuestionErrorV2()))
        )
      )
    )
  );

  upsertQuizQuestionV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.upsertQuizQuestionV2),
      switchMap(({ quizQuestion, quizAnswers }) =>
        concat(
          // need to remove null values, i.e. lecture since it's not required
          this.coursesService.upsertQuizQuestionV3(_.omitBy(quizQuestion!, _.isNil) as any),
          ...quizAnswers?.map((quizAnswer) => this.coursesService.upsertQuizAnswerV3(quizAnswer!))
        ).pipe(
          map(() => CourseActionsV2.upsertQuizQuestionSuccessV2()),
          catchError(() => of(CourseActionsV2.upsertQuizQuestionErrorV2()))
        )
      )
    )
  );
  deleteQuizQuestionV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.deleteQuizQuestionV2),
      switchMap(({ quizQuestionId }) =>
        this.coursesService.deleteQuizQuestionV3(quizQuestionId).pipe(
          map(() => CourseActionsV2.deleteQuizQuestionSuccessV2()),
          catchError(() => of(CourseActionsV2.deleteQuizQuestionErrorV2()))
        )
      )
    )
  );

  deleteQuizAnswerV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.deleteQuizAnswerV2),
      switchMap(({ quizAnswerId }) =>
        this.coursesService.deleteQuizAnswerV3(quizAnswerId).pipe(
          map(() => CourseActionsV2.deleteQuizAnswerSuccessV2()),
          catchError(() => of(CourseActionsV2.deleteQuizAnswerErrorV2()))
        )
      )
    )
  );

  // Assignments
  createAssignmentV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.createAssignmentV2),
      switchMap(({ subsection, assignment }) =>
        concat(
          this.coursesService.upsertSubsectionV3(_.omit(subsection!, 'assignment')),
          this.coursesService.upsertAssignmentV3(assignment!),
          this.coursesService.upsertSubsectionV3({ id: subsection!.id, assignment: assignment!.id })
        ).pipe(
          map(() => CourseActionsV2.createAssignmentSuccessV2()),
          catchError(() => of(CourseActionsV2.createAssignmentErrorV2()))
        )
      )
    )
  );

  upsertAssignmentV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.upsertAssignmentV2),
      switchMap(({ assignment, assignmentQuestions }) => {
        return concat(
          // need to remove null values, i.e. lecture since it's not required
          this.coursesService.upsertAssignmentV3(_.omitBy(assignment!, _.isNil) as any),
          ...assignmentQuestions?.map((assignmentQuestion) =>
            this.coursesService.upsertAssignmentQuestionV3(assignmentQuestion!)
          )
        ).pipe(
          map(() => CourseActionsV2.upsertAssignmentSuccessV2()),
          catchError(() => of(CourseActionsV2.upsertAssignmentErrorV2()))
        );
      })
    )
  );

  deleteAssignmentQuestionV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.deleteAssignmentQuestionV2),
      switchMap(({ assignmentQuestionId }) =>
        this.instructorService.deleteAssignmentQuestion(assignmentQuestionId).pipe(
          map(() => CourseActionsV2.deleteAssignmentQuestionSuccessV2()),
          catchError(() => of(CourseActionsV2.deleteAssignmentQuestionErrorV2()))
        )
      )
    )
  );

  // Annotations
  loadCoursesAnnotationsV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadAnnotationsV2),
      switchMap((params) => {
        return this.coursesService.getAnnotationsByProjectIdV3(params.projectId).pipe(
          map((data) => {
            return CourseActionsV2.loadAnnotationsSuccessV2(data);
          }),
          catchError((error) => of(CourseActionsV2.loadCourseDetailsFail({ error })))
        );
      })
    )
  );

  loadHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActions.loadHistory),
      switchMap(({ projectId }) =>
        this.annotationService.getAnnotationsByProjectId(projectId).pipe(
          map(({ courses, annotations }) => CourseActions.loadHistorySuccess({ courses, annotations })),
          catchError(() => of(CourseActions.loadHistoryError()))
        )
      )
    )
  );

  upsertLectureVideoInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.upsertLectureVideoInfoV2),
      mergeMap((action) => {
        const actionsToDispatch = [
          CourseActionsV2.uploadStatusUpdateV2({
            referenceId: action.video_info.lecture_id || '',
            status: action.video_info.status.length ? action.video_info.status : 'processing',
            error: action.video_info.error?.length ? action.video_info.error : '',
          }),
          // Dispatch other actions based on the video_info payload
        ];

        return actionsToDispatch;
      })
    )
  );

  // Load admin course version history
  loadCourseV2 = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadCourseV2),
      mergeMap((action) => {
        this.loaderService.loaderAction('loading-admin-courses-curriculumV2', 'loading');
        this.loaderService.loaderAction('loading-admin-courses-detailsV2', 'loading');
        this.loaderService.loaderAction('loading-admin-courses-learnerV2', 'loading');
        // TODO: Remove this payload, Once new API will be ready
        let courseActionsPayload: CourseActionsPayload = {
          courseId: '',
        };
        if (action.params?.fixed_source) {
          courseActionsPayload = {
            courseId: action.courseId,
            params: { fixed_source: true },
          };
        } else {
          courseActionsPayload = {
            courseId: action.courseId,
          };
        }
        const actionsToDispatch = [
          // CourseActions.loadCourse(courseActionsPayload),
          CourseActionsV2.loadAdminCourseCurriculum({ courseId: action.courseId, params: {} }),
          CourseActionsV2.loadAdminCourseDetails({ courseId: action.courseId }),
          CourseActionsV2.loadAdminCourseLearner({ courseId: action.courseId }),
          // CourseActions.loadCourse({ courseId: action.courseId, params: { fixed_source: true } })
        ];

        return actionsToDispatch;
      })
    )
  );

  loadCoursesVersionHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadAdminCourseHistory),
      switchMap((params) => {
        return this.coursesService.getCoursesHistoryByProjectId(params.courseId).pipe(
          map((data) => {
            return CourseActionsV2.loadAdminCourseHistorySuccess(data);
          }),
          catchError((error) => of(CourseActionsV2.loadAdminCourseHistoryFailed({ error })))
        );
      })
    )
  );

  loadAdminCourseCurriculum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadAdminCourseCurriculum),
      switchMap((payload) => {
        return this.coursesService.getAdminCourseCurriculum(payload.courseId, payload.params).pipe(
          map((data) => {
            this.loaderService.loaderAction('loading-admin-courses-curriculumV2', 'success');
            return CourseActionsV2.loadAdminCourseCurriculumSuccess(data);
          }),
          catchError((error) => {
            this.loaderService.loaderAction('loading-admin-courses-curriculumV2', 'failed');
            return of(CourseActionsV2.loadAdminCourseCurriculumFail({ error }));
          })
        );
      })
    )
  );

  loadAdminCourseDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadAdminCourseDetails),
      switchMap((params) => {
        return this.coursesService.getAdminCourseDetails(params.courseId).pipe(
          map((data) => {
            this.loaderService.loaderAction('loading-admin-courses-detailsV2', 'success');
            return CourseActionsV2.loadAdminCourseDetailsSuccess(data);
          }),
          catchError((error) => {
            this.loaderService.loaderAction('loading-admin-courses-detailsV2', 'failed');
            return of(CourseActionsV2.loadAdminCourseDetailsFail({ error }));
          })
        );
      })
    )
  );

  loadAdminCourseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadAdminCourseDetailsSuccess),
      mergeMap((courseDetail) => {
        return of(
          CourseActionsV2.loadAdminCourseHistory({ courseId: courseDetail.project }),
          CourseActionsV2.loadAnnotationsV2({ projectId: courseDetail.project })
          // CourseActions.loadHistory({ projectId: courseDetail.project })
        );
      })
    )
  );

  loadAdminCourseLearner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseActionsV2.loadAdminCourseLearner),
      switchMap((params) => {
        return this.coursesService.getAdminCourseIntend(params.courseId).pipe(
          map((data) => {
            this.loaderService.loaderAction('loading-admin-courses-learnerV2', 'success');
            return CourseActionsV2.loadAdminCourseLearnerSuccess(data);
          }),
          catchError((error) => {
            this.loaderService.loaderAction('loading-admin-courses-learnerV2', 'failed');
            return of(CourseActionsV2.loadAdminCourseLearnerFail({ error }));
          })
        );
      })
    )
  );
}
