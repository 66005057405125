import { createAction, props } from '@ngrx/store';
import {
  ActionsParams,
  AnnotationsByProjectResultV2,
  AssignmentQuestionV3,
  AssignmentV3,
  CourseCurriculumResponseV2,
  CourseCurriculumSectionV2,
  CourseDetailsModelV2,
  CourseLearnerModelV2,
  CoursesVersionHistory,
  LectureResourceV3,
  LectureV3,
  Params,
  PartialEntity,
  QuizAnswerV3,
  QuizQuestionV3,
  QuizV3,
  StreamVideoInfoV3,
  SubsectionDetailV3,
  SubsectionV3,
} from '../../models';
import { ActivityStateV2 } from './course.state';

export const CourseActionsV2 = {
  // Instructor
  // Course details
  loadCourseDetails: createAction('[CourseV2 Details] Load Course', props<{ courseId: string }>()),
  loadCourseDetailsSuccess: createAction('[CourseV2 Details] Load Course Success', props<CourseDetailsModelV2>()),
  loadCourseDetailsFail: createAction('[CourseV2 Details] Load Course Fail', props<{ error: unknown }>()),

  upsertCourseDetailsVideoInfoV2: createAction(
    '[CourseV2] Upsert Course Details Video Info',
    props<{ video_info: StreamVideoInfoV3 }>()
  ),

  upsertCourse: createAction('[CourseV2 Details] Upsert Course', props<{ courseDetails: CourseDetailsModelV2 }>()),
  upsertCourseSuccess: createAction(
    '[CourseV2 Details] Upsert Course Success',
    props<{ courseDetails: CourseDetailsModelV2 }>()
  ),
  upsertCourseError: createAction('[CourseV2 Details] Upsert Course Error', props<{ error: unknown }>()),

  // Course learner
  loadCourseLearner: createAction('[CourseV2 Learner] Load Course', props<{ courseId: string }>()),
  loadCourseLearnerSuccess: createAction('[CourseV2 Learner] Load Course Success', props<CourseLearnerModelV2>()),
  loadCourseLearnerFail: createAction('[CourseV2 Learner] Load Course Fail', props<{ error: unknown }>()),

  // Course curriculum
  loadCourseCurriculum: createAction(
    '[CourseV2 Curriculum] Load Course',
    props<{ courseId: string; params: Params }>()
  ),
  loadCourseCurriculumSuccess: createAction(
    '[CourseV2 Curriculum] Load Course Success',
    props<CourseCurriculumResponseV2>()
  ),
  loadCourseCurriculumFail: createAction('[CourseV2 Curriculum] Load Course Fail', props<{ error: unknown }>()),
  upsertCourseCurriculum: createAction(
    '[CourseV2 Curriculum] Upsert Course Curriculum',
    props<{ results: CourseCurriculumSectionV2[] }>()
  ),

  autoLoadCourseCurriculum: createAction(
    '[CourseV2 Curriculum] Auto Load Course',
    props<{ courseId: string; params: Params }>()
  ),
  autoLoadCourseCurriculumSuccess: createAction(
    '[CourseV2 Curriculum] Auto Load Course Success',
    props<CourseCurriculumResponseV2>()
  ),
  autoLoadCourseCurriculumFail: createAction(
    '[CourseV2 Curriculum] Auto Load Course Fail',
    props<{ error: unknown }>()
  ),

  upsertCourseSubsection: createAction(
    '[CourseV2 Curriculum] Upsert Course Subsection',
    props<{ results: SubsectionDetailV3[]; sectionId: string }>()
  ),

  // activity
  createActivityV2: createAction('[CourseV2] Create Activity', props<{ sectionId: string }>()),
  editActivityV2: createAction(
    '[CourseV2] Edit Activity',
    props<{ sectionId: string; subsectionId: string; activityId: string; activityState: ActivityStateV2 }>()
  ),
  closeActivityV2: createAction('[CourseV2] Close Activity'),
  modalNextV2: createAction('[CourseV2] Modal Next', props<{ activityState: ActivityStateV2 }>()),
  reloadModalV2: createAction('[CourseV2] Modal Reload', props<{ activityState: ActivityStateV2 }>()),

  // section
  createSectionV2: createAction(
    '[CourseV2] Create Section',
    props<{ section: PartialEntity<CourseCurriculumSectionV2> }>()
  ),
  createSectionSuccessV2: createAction('[CourseV2] Create Section Success'),
  createSectionErrorV2: createAction('[CourseV2] Create Section Error'),

  upsertSectionV2: createAction(
    '[CourseV2] Upsert Section',
    props<{ section: PartialEntity<CourseCurriculumSectionV2> }>()
  ),
  upsertSectionSuccessV2: createAction('[CourseV2] Upsert Section Success'),
  upsertSectionErrorV2: createAction('[CourseV2] Upsert Section Error'),

  deleteSectionV2: createAction('[CourseV2] Delete Section', props<{ sectionId: string; params: object }>()),
  deleteSectionSuccessV2: createAction('[CourseV2] Delete Section Success'),
  deleteSectionErrorV2: createAction('[CourseV2] Delete Section Error'),

  deleteSubsectionV2: createAction(
    '[CourseV2] Delete Subsection',
    props<{ subsectionId: string; sectionId: string }>()
  ),
  deleteSubsectionSuccessV2: createAction('[CourseV2] Delete Subsection Success'),
  deleteSubsectionErrorV2: createAction('[CourseV2] Delete Subsection Error'),

  // quiz
  createQuizV2: createAction('[CourseV2] Create Quiz', props<{ subsection: SubsectionV3; quiz: QuizV3 }>()),
  createQuizSuccessV2: createAction('[CourseV2] Create Quiz Success'),
  createQuizErrorV2: createAction('[CourseV2] Create Quiz Error'),

  upsertQuizV2: createAction('[CourseV2] Upsert Quiz', props<{ quiz: PartialEntity<QuizV3> }>()),
  upsertQuizSuccessV2: createAction('[CourseV2] Upsert Quiz Success'),
  upsertQuizErrorV2: createAction('[CourseV2] Upsert Quiz Error'),

  createQuizQuestionV2: createAction(
    '[CourseV2] Create Quiz Question',
    props<{
      quizQuestion: PartialEntity<QuizQuestionV3>;
      quizAnswers: PartialEntity<QuizAnswerV3>[];
      sectionId: string;
      subsectionId: string;
    }>()
  ),
  createQuizQuestionSuccessV2: createAction('[CourseV2] Create Quiz Question Success'),
  createQuizQuestionErrorV2: createAction('[CourseV2] Create Quiz Question Error'),

  upsertQuizQuestionV2: createAction(
    '[CourseV2] Upsert Quiz Question',
    props<{
      quizQuestion: PartialEntity<QuizQuestionV3>;
      quizAnswers: PartialEntity<QuizAnswerV3>[];
      sectionId: string;
      subsectionId: string;
    }>()
  ),
  upsertQuizQuestionSuccessV2: createAction('[CourseV2] Upsert Quiz Question Success'),
  upsertQuizQuestionErrorV2: createAction('[CourseV2] Upsert Quiz Question Error'),

  deleteQuizQuestionV2: createAction(
    '[CourseV2] Delete Quiz Question',
    props<{
      quizQuestionId: string;
      sectionId: string;
      subsectionId: string;
    }>()
  ),
  deleteQuizQuestionSuccessV2: createAction('[CourseV2] Delete Quiz Question Success'),
  deleteQuizQuestionErrorV2: createAction('[CourseV2] Delete Quiz Question Error'),

  deleteQuizAnswerV2: createAction(
    '[CourseV2] Delete Quiz Answer',
    props<{
      quizAnswerId: string;
      quizQuestionId: string;
      sectionId: string;
      subsectionId: string;
    }>()
  ),
  deleteQuizAnswerSuccessV2: createAction('[CourseV2] Delete Quiz Answer Success'),
  deleteQuizAnswerErrorV2: createAction('[CourseV2] Delete Quiz Answer Error'),

  // assignment
  createAssignmentV2: createAction(
    '[CourseV2] Create Assignment',
    props<{ subsection: SubsectionV3; assignment: AssignmentV3 }>()
  ),
  createAssignmentSuccessV2: createAction('[CourseV2] Create Assignment Success'),
  createAssignmentErrorV2: createAction('[CourseV2] Create Assignment Error'),

  upsertAssignmentV2: createAction(
    '[CourseV2] Upsert Assignment',
    props<{ assignment: PartialEntity<AssignmentV3>; assignmentQuestions: PartialEntity<AssignmentQuestionV3>[] }>()
  ),
  upsertAssignmentSuccessV2: createAction('[CourseV2] Upsert Assignment Success'),
  upsertAssignmentErrorV2: createAction('[CourseV2] Upsert Assignment Error'),

  deleteAssignmentQuestionV2: createAction(
    '[CourseV2] Delete Assignment Question',
    props<{ assignmentQuestionId: string; sectionId: string; subsectionId: string }>()
  ),
  deleteAssignmentQuestionSuccessV2: createAction('[CourseV2] Delete Assignment Question Success'),
  deleteAssignmentQuestionErrorV2: createAction('[CourseV2] Delete Assignment Question Error'),

  // lecture
  createLectureV2: createAction('[CourseV2] Create Lecture', props<{ subsection: SubsectionV3; lecture: LectureV3 }>()),
  createLectureSuccessV2: createAction('[CourseV2] Create Lecture Success', props<{ subsection: any; lecture: any }>()),
  createLectureErrorV2: createAction('[CourseV2] Create Lecture Error'),

  upsertLectureV2: createAction(
    '[CourseV2] Upsert Lecture',
    props<{ lecture: PartialEntity<LectureV3>; lectureResources: PartialEntity<LectureResourceV3>[] }>()
  ),
  upsertLectureSuccessV2: createAction('[CourseV2] Upsert Lecture Success', props<{ lecture: LectureV3 }>()),
  upsertLectureErrorV2: createAction('[CourseV2] Upsert Lecture Error'),

  upsertLectureResourceSuccessV2: createAction('[CourseV2] Upsert Lecture Resource Success'),
  upsertLectureResourceErrorV2: createAction('[CourseV2] Upsert Lecture Resource Error'),

  deleteLectureResourceV2: createAction(
    '[CourseV2] Delete Lecture Resource',
    props<{ lectureResourceId: string; sectionId: string; subsectionId: string }>()
  ),
  deleteLectureResourceSuccessV2: createAction('[CourseV2] Delete Lecture Resource Success'),
  deleteLectureResourceErrorV2: createAction('[CourseV2] Delete Lecture Resource Error'),

  upsertLectureVideoInfoV2: createAction(
    '[CourseV2] Upsert Lecture Video Info',
    props<{ video_info: StreamVideoInfoV3 }>()
  ),

  // upload
  uploadStartV2: createAction(
    '[CourseV2 Curriculum] Upload',
    props<{ referenceId: string; fileName: string; fileType: string; status?: string }>()
  ),
  uploadProgressV2: createAction(
    '[CourseV2 Curriculum] Upload Progress',
    props<{
      referenceId: string;
      progressPercent: number;
      bytesTotal: number;
      bytesUploaded: number;
      status?: string;
    }>()
  ),
  uploadStatusUpdateV2: createAction(
    '[CourseV2 Curriculum] Upload Processing',
    props<{ referenceId: string; status?: string; error?: string }>()
  ),
  uploadCompleteV2: createAction(
    '[CourseV2 Curriculum] Upload Complete',
    props<{ referenceId: string; status?: string }>()
  ),
  uploadAbortV2: createAction('[CourseV2 Curriculum] Upload Abort', props<{ referenceId: string }>()),

  // Annotations
  loadAnnotationsV2: createAction('[CourseV2] Load Annotations', props<{ projectId: string }>()),
  loadAnnotationsSuccessV2: createAction('[CourseV2] Load Annotations Success', props<AnnotationsByProjectResultV2>()),
  loadAnnotationsFailV2: createAction('[CourseV2] Load Annotations Fail', props<{ error: unknown }>()),

  // Admin
  loadCourseV2: createAction('[Course] Load Course V2', props<{ courseId: string; params?: ActionsParams }>()),

  loadAdminCourseHistory: createAction('[CourseV2] Load Admin Courses History', props<{ courseId: string }>()),
  loadAdminCourseHistorySuccess: createAction(
    '[CourseV2] Load Admin Courses History Success',
    props<CoursesVersionHistory>()
  ),
  loadAdminCourseHistoryFailed: createAction(
    '[CourseV2] Load Admin Courses History Failed',
    props<{ error: unknown }>()
  ),

  loadAdminCourseCurriculum: createAction(
    '[CourseV2 Curriculum Admin] Load Course Curriculum',
    props<{ courseId: string; params: Params }>()
  ),
  loadAdminCourseCurriculumSuccess: createAction(
    '[CourseV2 Curriculum Admin] Load Course Success',
    props<CourseCurriculumResponseV2>()
  ),
  loadAdminCourseCurriculumFail: createAction(
    '[CourseV2 Curriculum Admin] Load Course Fail',
    props<{ error: unknown }>()
  ),

  loadAdminCourseDetails: createAction('[CourseV2 Details Admin] Load Course', props<{ courseId: string }>()),
  loadAdminCourseDetailsSuccess: createAction(
    '[CourseV2 Details Admin] Load Course Success',
    props<CourseDetailsModelV2>()
  ),
  loadAdminCourseDetailsFail: createAction('[CourseV2 Details Admin] Load Course Fail', props<{ error: unknown }>()),

  loadAdminCourseLearner: createAction('[CourseV2 Learner Admin] Load Course', props<{ courseId: string }>()),
  loadAdminCourseLearnerSuccess: createAction(
    '[CourseV2 Learner Admin] Load Course Success',
    props<CourseLearnerModelV2>()
  ),
  loadAdminCourseLearnerFail: createAction('[CourseV2 Learner Admin] Load Course Fail', props<{ error: unknown }>()),
};
